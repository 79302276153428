<template>
    <main>
        <page-header-compact>
            Registro de Resultados laboratorio
        </page-header-compact>

        <div class="container-fluid">
            <div class="card mt-4">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-6 col-sm-12 col-md-12">
                            <div class="row">
                                <div class="form-group col-lg-12 col-md-12 col-sm-12">
                                    <label class="form-label">No. Orden de trabajo </label>
                                    <orden-trabajo-field v-model="ordentrabajo"></orden-trabajo-field>
                                </div>
                                <div class="form-group col-lg-12 col-md-12 col-sm-12">
                                    <label class="form-label">Fecha </label>
                                    <input
                                        type="date"
                                        class="form-control form-control-sm"
                                        v-model="fecha"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
                            <div class="text-center card-waves mt-2 card-header-actions">
                                <div class="card-body " >
                                    <div v-if="Object.keys(usuario).length">
                                        <div class="form-group">
                                        <strong >{{usuario.nombre_1}} {{usuario.nombre_2}} {{usuario.apellido_1}} {{usuario.apellido_2}}</strong>
                                        <h6 class="text-body mt-2">FECHA DE NACIMIENTO: {{usuario.fecha_nacimiento}}</h6>
                                        <h6 class="text-body">EDAD : <span class="badge badge-secondary">{{usuario.years}}</span> </h6>
                                        </div>
                                    </div>
                                    <div class="align-center " v-else >
                                        <img class="mb-0" src="@/assets/img-generales/error.png" width="96">
                                        Sin resultados...
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-4">
                <div class="card-header text-primary">Examenes</div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-4"  v-for="item in detalles" :key="item.id">
                            <div class="card">
                                <div class="card-body"> 
                                    <h6 class="text-black"> {{item.nombre}} - Valor de referencia [{{item.examen.valores_referencia}}] {{item.examen.unidad}}</h6>
                                    <texto
                                        v-model="item.resultado"
                                        v-if="item.tipo === 'texto'"
                                    ></texto>
                                    <numerico
                                        v-model="item.resultado"
                                        v-if="item.tipo === 'numero'"
                                    ></numerico>
                                    <opciones
                                        :examen="item.examen"
                                        v-model="item.resultado"
                                        v-if="item.examen.tipo === 'opcion'"
                                    ></opciones>    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <button class="btn btn-success" @click="save()">
                        <i class="fas fa-save fa-fw text-white-50"></i>
                            Guardar
                    </button>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import PageHeaderCompact from "../../../../components/layouts/content/page-header-compact";
import OrdenTrabajoField from "../../../../components/common/OrdenTrabajoField";
import texto from "./tipos/texto";
import numerico from "./tipos/numerico";
import opciones from "./tipos/opciones";
import Swal from "sweetalert2";
import resultadosLaboratorioService from "../../../../services/resultadosLaboratorioService";
import asignacionAreasService from "../../../../services/asignacionAreasService";

import {isEmpty} from "lodash";

export default {
    components: {
        PageHeaderCompact,OrdenTrabajoField,texto,numerico,opciones
    },
    data(){
        return {
            ordentrabajo:{},
            usuario:{},
            fecha:'',
            detalles:[],
            tieneRespuestas:false
        }
    },
    watch:{
        ordentrabajo:{
            handler: async function () {
                if(!isEmpty(this.ordentrabajo)){

                    this.usuario=this.ordentrabajo.usuario;

                    const respuestas = await resultadosLaboratorioService.showByOrdenTrabajo(this.ordentrabajo.id);

                    if(!isEmpty(respuestas.data)) this.tieneRespuestas = true;


                    let examenes = [];

                    const testWithSubtest = this.ordentrabajo.detalles.filter(x => x.examen.subexamen.length);
                    const testWithoutSubtest = this.ordentrabajo.detalles.filter(x => x.examen.subexamen.length === 0);

                    testWithSubtest.forEach(test => {
                        examenes.push(...test.examen.subexamen.map(subtest => {
                            
                            let respuesta = '';

                            if(!isEmpty(respuestas.data)){
                                
                                const tieneRespuesta = respuestas.data.detalles.find(x => parseInt(x.id_examen) === parseInt(subtest.id_examen));
                                
                                if(tieneRespuesta !== undefined){
                                    respuesta = tieneRespuesta.resultado;
                                }
                               
                            }

                            return {
                                id:subtest.id,
                                nombre:subtest.nombre,
                                tipo:subtest.tipo,
                                opciones:subtest.opciones,
                                resultado:respuesta,
                            }
                        }));
                    });

                    examenes.push(...testWithoutSubtest.map(test => {
                        
                        let respuesta = '';
                       
                        if(!isEmpty(respuestas.data)){
                            
                            const tieneRespuesta = respuestas.data.detalles.find(x => parseInt(x.id_examen) === parseInt(test.id_examen));
                            
                            if(tieneRespuesta !== undefined){
                                respuesta = tieneRespuesta.resultado;
                            }
                        }
                        return {
                            id:test.examen.id,
                            nombre:test.examen.nombre,
                            tipo:test.examen.tipo,
                            opciones:test.examen.opciones,
                            id_area:test.examen.id_area,
                            resultado:respuesta,
                            examen:test.examen
                        }
                    }));

                    const {data} = await asignacionAreasService.showByDate(this.ordentrabajo.fecha);
                    
                    const asignacion = data.length ? data.pop() : [];

                    examenes = examenes.map(x => {
                        const id_user = asignacion.detalles.find(y => y.id_area_lab === x.id_area)?.id_profesional || 0;
                        return {
                            ...x,
                            id_profesional:id_user
                        }
                    });

                    this.detalles = examenes;
                    this.fecha = this.ordentrabajo.fecha;
               
                }else{
                    this.usuario={};
                    this.detalles=[];
                }
            },
            deep: true
        },

    },
    methods:{
        async save(){           
            try {
                let resultados=[];
                
                this.detalles.forEach(x=>{ 

                    let examenes={
                        nombre:x.examen.nombre,
                        id_examen:x.examen.id,
                        resultado:x.resultado,
                        id_profesional:x.id_profesional,
                        valores_referencia:x.valores_referencia
                    }

                   /*  let subexamenes=x.examen.subexamen.map(y=>{
                        return {
                            nombre:y.nombre,
                            id_examen:y.id,
                            resultado:y.resultado,
                            id_profesional:y.id_profesional
                        }
                    }); */
                    
                    
                    let resultados_examenes=[
                       examenes,
                       //...subexamenes
                    ]
                        
                    resultados.push(...resultados_examenes);
                });

                let resultados_laboratorios={
                    id_orden_trabajo: this.ordentrabajo.id,
                    fecha:this.fecha,
                    resultados:resultados
                }
             
            
                
                this.LoaderSpinnerShow();
        
                if(this.tieneRespuestas){
                    await resultadosLaboratorioService.update(resultados_laboratorios);
                }else{
                    await resultadosLaboratorioService.store(resultados_laboratorios);
                    this.tieneRespuestas = true;
                }

                this.LoaderSpinnerHide();
                
                Swal.fire("Datos Guardados con exito", "", "success");
                
                
            } catch (error) {
                console.log(error);
                this.LoaderSpinnerHide();
                Swal.fire("Upss", "Ocurrio un error al procesar la solicitud", "error");
            }
        }
    }
   
  
}
</script>
